import { useEffect } from "react";
import { useRouter } from "next/router";
import { CircularProgress } from "@jt/ui";
import useAuth from "@/useAuth";
import { useTemplate } from "@/components/layouts/Template";
import LoginOrSignUp from "@/components/pages/login/LoginOrSignUp";
import { checkIsNonEmptyString } from "@/typeGuards";

function HomePage() {
  const router = useRouter();
  const { user, isAuthenticated } = useAuth();
  const url = router.asPath;
  const isRedirectUrl = url.toLocaleLowerCase().includes("redirect=");
  const redirectUrl = isRedirectUrl ? url.split("?redirect=").at(1) : "";

  const { setHasContainer, setHasHero, setHasNav, setHasSideMenu } =
    useTemplate({
      title: "Home",
    });

  useEffect(function setMenuAndHeroOnMountAndResetOnUnmount() {
    // Set the state when the component mounts
    setHasContainer(false);
    setHasHero(false);
    setHasNav(false);
    setHasSideMenu(false);

    // Reset the state back to default when the component unmounts
    return () => {
      setHasContainer(true);
      setHasHero(true);
      setHasNav(true);
      setHasSideMenu(true);
    };
  }, []);

  useEffect(function redirectUserToAccountSelectPage() {
    if (isAuthenticated && user && !checkIsNonEmptyString(redirectUrl)) {
      void router.replace("/user/account-select");
    }
  }, []);

  return !isAuthenticated || !user ? <LoginOrSignUp /> : <CircularProgress />;
}

export default HomePage;
