import Image from "next/image";
import { useState } from "react";
import {
  Heading,
  FormDesktopBackground,
  CircularProgress,
  Paragraph,
  CircledInfo,
  Stack,
  Button,
} from "@jt/ui";
import RegisterInfoPopup from "./RegisterInfoPopup";
import NewUserInfoPopup from "./NewUserInfoPopup";
import config from "@/config";
import LoginButton from "@/components/LoginButton";
import { CookiesMachineContext } from "@/components/machines/cookiesMachine/CookiesMachineContext";

function LoginOrSignUp() {
  const [isRegisterInfoPopupOpen, setIsRegisterInfoPopupOpen] = useState(false);
  const cookiesState = CookiesMachineContext.useSelector((state) => state);

  if (
    !config.app.isNativeApp &&
    (cookiesState.matches("CheckingIfUserHasPreviouslyLoggedIn") ||
      cookiesState.matches("RedirectingToLoginScreen"))
  ) {
    return <CircularProgress data-cy="circularLoader" />;
  }

  function handleRegisterInfoPopupOpen() {
    setIsRegisterInfoPopupOpen(!isRegisterInfoPopupOpen);
  }

  return (
    <>
      <FormDesktopBackground
        hasDesktopBackgroundInMobile
        isApp={config.app.isNativeApp}
        shouldFullHeight
      >
        <Image
          alt="JT Logo"
          className="mb-8 md:hidden"
          height="72"
          src="/images/JT_Logo_white@3x.png"
          width="106"
        />
        <Image
          alt="JT Logo"
          className="mx-auto mb-8 hidden md:block"
          height="72"
          src="/images/JT_Logo.png"
          width="106"
        />
        <Stack
          className="mx-auto min-h-[90vh] text-white md:min-h-max md:w-5/6 md:text-center md:text-jt-blue"
          spacing={10}
        >
          <Stack className="text-left" spacing={4}>
            <Heading as="h2" className="md:text-center">
              Welcome to My JT
            </Heading>
            <Paragraph size="large">
              Before you can link your JT services to your &apos;My JT&apos; you
              need to create a JT ID. Please click &apos;Register&apos; to
              create your JT ID.{" "}
              <Button
                className="!inline !p-0"
                color="secondary"
                data-cy="register-info-popup"
                icon={<CircledInfo className="text-white md:text-secondary" />}
                link
                onClick={handleRegisterInfoPopupOpen}
              />
            </Paragraph>
          </Stack>
          <Stack className="mb-4 text-center" spacing={4}>
            <LoginButton
              color="tertiary"
              data-cy="button-signup-index"
              isSignUp
              label="Register"
            />
            <Paragraph
              className="flex flex-col justify-center font-semibold xs:flex-row"
              size="large"
            >
              Already got a JT ID?
              <LoginButton
                className="cursor-pointer text-jt-neon underline xs:ml-2 md:text-primary"
                color="primary"
                data-cy="button-login-index"
                isLink
              />
            </Paragraph>
          </Stack>
        </Stack>
      </FormDesktopBackground>
      {isRegisterInfoPopupOpen ? (
        <RegisterInfoPopup onClose={handleRegisterInfoPopupOpen} />
      ) : null}
      {cookiesState.matches("ShowingNewUserPopup") && <NewUserInfoPopup />}
    </>
  );
}

export default LoginOrSignUp;
